import Layout from "@components/layout";
import { Box, Heading, Button } from "grommet";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import rateLimitPng from "public/assets/images/rateLimit.png";
import Image from "next/legacy/image";
import { useState } from "react";
import { useRouter } from "next/router";

export default function RateLimit() {
  const router = useRouter();
  const large = useIsLarge();
  const [refreshed, setRefreshed] = useState(false);

  return (
    <Layout seo={{ title: "Page overload" }}>
      <Box
        direction={large ? "row" : "column"}
        align="center"
        justify={large ? "center" : "start"}
        height={{ min: "medium" }}
        gap={large ? "large" : "small"}
        pad="large"
      >
        <Image
          height={large ? "150px" : "80px"}
          width={large ? "150px" : "80px"}
          src={rateLimitPng}
          alt="Page overload"
        />
        <Box align={large ? "start" : "center"}>
          <Heading level="2">Page overload</Heading>

          <Button
            onClick={() => {
              setRefreshed(true);
              setTimeout(() => {
                setRefreshed(false);
                const url = decodeURIComponent(router.query.url);
                router.push(url);
              }, 1000);
            }}
            color="brand"
            label="Open the page"
            plain
            disabled={refreshed}
            hoverIndicator
          />
        </Box>
      </Box>
    </Layout>
  );
}
